import React, { useRef, useEffect } from 'react';
import Utils from 'utilities/Utils';
import { useLocation } from 'react-router-dom';
import { RepCard, Icon } from 'ComponentLibrary';
import ContentLoader from 'react-content-loader';

const ContactTree = ({ section, sectionIndex, isSkeleton }) => {
    const ref = useRef(null);
    const location = useLocation();

    const getDeepLinkedSection = (path) => {
        if (path.startsWith('/contacts/')) {
            const scrollPath = path.split('/contacts/')[1];
            if (scrollPath && scrollPath.replace("-", "") === section.name.replace(/\s/g, '').toLowerCase() && ref.current) {
                ref.current.scrollIntoView();
            }
        }
        return null;
    };

    useEffect(() => {
        if (section && !isSkeleton) {
            const deepLinkedSection = getDeepLinkedSection(location.pathname);
        }
    }, [section, isSkeleton]);

    return (
        <section ref={ref} key={`${Utils.slugify(section.name)}-${sectionIndex}`} className="contact-group" data-testid="contact-group">
            {!isSkeleton && section.contacts && section.contacts.length > 0 && section.name && (
                <h2 className="contact-group__title" data-testid="contact-group__title">
                    {section.name}
                    {section.coordinates && section.coordinates.latitude && (
                        <a target="_blank" rel="nofollow noopener noreferrer" className="contact-group__map-link" data-testid="contact-group__map-link" href={`https://www.google.com/maps/dir/?api=1&origin&destination=${section.coordinates.latitude},${section.coordinates.longitude}`}>
                            <Icon size={0.75} icon="map" color="boston-blue" />
                            Map Our Location
                        </a>
                    )}
                </h2>
            )}
            {isSkeleton && (
                <div className="contact-group__title" data-testid="contact-tree__skeleton-title">
                    <ContentLoader height="16">
                        <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="410"
                            height="16"
                        />
                    </ContentLoader>
                </div>
            )}
            <ContactTreeSection
                isSkeleton={isSkeleton}
                contacts={section.contacts}
            />
        </section>
    );
};

const ContactTreeSection = ({ contacts, isSkeleton, skeletonSections }) => {
    if (isSkeleton && (!contacts || !Array.isArray(contacts))) {
        contacts = new Array(skeletonSections);
        contacts.fill(true);
    }

    return (
        <ul className="contact-group__list">
            {contacts.map((contact, index) => {
                const propsForCard = Utils.convertToRepCardData(contact);
                return (
                    <RepCard
                        key={index}
                        as="li"
                        classes={['contact-group__list-item']}
                        {...propsForCard}
                        skeleton={isSkeleton}
                    />
                );
            })}
        </ul>
    );
};

export default ContactTree;
