import React from 'react';
import PropTypes from 'prop-types';
import './LocationCard.scss';
import SanitizeService from 'services/SanitizeService';
import Utils from 'utilities/Utils';
import { CSSTransition } from 'react-transition-group';
import Constants from 'Constants';

export default function LocationCard(props) {
    const { data, mobileOnly, left, tabslug, overrideTitle } = props;
    if (!data) {
        return null;
    }

    const { address, hours, branchName, phone, fax, coordinates } = data;
    let latitude = null;
    let longitude = null;
    if (coordinates) {
        latitude = coordinates.latitude;
        longitude = coordinates.longitude;
    }
    const { city, state, streetAddress1, streetAddress2, zip } = address;
    const comboAddress = encodeURIComponent(`${streetAddress1} ${streetAddress2} ${city} ${state} ${zip}`).replace(/%20/g, "+");
    let cardTitle = tabslug === 'closing' ? Constants.locationCard.closingHeadline : Constants.locationCard.headline;
    if (overrideTitle) {
        cardTitle = overrideTitle;
    }
    return (
        <CSSTransition in appear timeout={500} classNames="fade-and-rise">
            <section
                className={`location-card ${left && 'location-card--left-column'
                    } ${mobileOnly && 'location-card--mobile-only'}`}
                data-testid="mi-location-card"
            >
                <h1
                    className={`location-card__title ${left && 'location-card__title--left'
                        }`}
                    data-testid="location-card__title"
                >
                    {cardTitle}
                </h1>

                <div
                    className={`location-card__map ${left && 'location-card__map--left'
                        }`}
                    data-testid="location-card__map"
                >
                    <a
                        className={`location-card__link location-card__link--${tabslug} ${left && 'location-card__link--left'
                            }`}
                        data-testid="location-card__link"
                        href={`https://www.google.com/maps/place/${(streetAddress1.replace(/ /g, '+'))}+${(streetAddress2.replace(/ /g, '+'))}+${window.encodeURIComponent(city.replace(/ /g, '+'))},+${state}+${zip}`}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        {latitude && longitude && (
                            <img
                                lazy="true"
                                src={`https://maps.googleapis.com/maps/api/staticmap?size=${left && window.innerWidth >= 764 ? '183x123' : '564x164'}&markers=size:small|color:0xA8160F|${latitude},${longitude}&zoom=18&sensor=false&visual_refresh=true&key=AIzaSyBp5ubxrEwsokyEZ9TVTMvEawOPB5dOZvc`}
                                className={`location-card__map-image ${left && 'location-card__map-image--left'}`}
                                alt="Map Of Our Office"
                            />
                        )}
                        {(!latitude || !longitude) && (
                            <img
                                lazy="true"
                                src={`https://maps.googleapis.com/maps/api/staticmap?center=center=${comboAddress}&size=${left && window.innerWidth >= 764 ? '183x123' : '564x164'}&zoom=18&sensor=false&visual_refresh=true&key=AIzaSyBp5ubxrEwsokyEZ9TVTMvEawOPB5dOZvc`}
                                className={`location-card__map-image ${left && 'location-card__map-image--left'}`}
                                alt="Map Of Our Office"
                            />
                        )}
                    </a>
                </div>

                <div
                    className={`location-card__info ${left && 'location-card__info--left'
                        }`}
                    data-testid="location-card__info"
                >
                    <div
                        className={`location-card__info-piece ${left && 'location-card__info-piece--left'
                            }`}
                        data-testid="location-card__info-piece"
                    >
                        <h2 className="location-card__branch">{branchName}</h2>
                        <address className="location-card__address">
                            <p>
                                {streetAddress1} {streetAddress2}
                            </p>
                            <p>
                                {city}, {state} {zip}
                            </p>
                        </address>
                        {phone && (
                            <p data-testid="location-card__phone">
                                Phone:{' '}
                                <a href={`tel:${phone}`}>
                                    {Utils.formatPhone(phone)}
                                </a>
                            </p>
                        )}
                        {fax && <p>Fax: {Utils.formatPhone(fax)}</p>}
                    </div>
                    {hours && (
                        <div
                            className={`location-card__info-piece ${left && 'location-card__info-piece--left'
                                }`}
                            data-testid="location-card__hours"
                        >
                            <h6 className="location-card__branch">
                                {Constants.locationCard.hours}
                            </h6>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: SanitizeService.sanitize(hours),
                                }}
                            />
                        </div>
                    )}
                </div>
            </section>
        </CSSTransition>
    );
}

LocationCard.defaultProps = {
    data: null,
    mobileOnly: false,
    left: false,
    tabslug: '',
};

LocationCard.propTypes = {
    data: PropTypes.object,
    left: PropTypes.bool,
    mobileOnly: PropTypes.bool,
    tabslug: PropTypes.string,
};
